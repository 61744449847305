import { useEffect } from "react";

export function useGoogleTagManager(id?: string) {
  /*
    <!-- Google Tag Manager -->
    <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-********');</script>
    <!-- End Google Tag Manager -->
    */

  useEffect(() => {
    setTimeout(() => {
      if (!id) {
        console.debug("No config.apis.google_tag_manager_id");
        return;
      }
      console.debug("Will load GTM.");

      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js"
      });

      // @ts-ignore
      window.gtag = function () {
        // @ts-ignore
        window.dataLayer.push(arguments);
      };

      // @ts-ignore
      const script = document.createElement("script");
      script.async = true;
      script.defer = true;
      script.src = "https://www.googletagmanager.com/gtm.js?id=" + id;
      // @ts-ignore
      document.body.append(script);
    }, 250);
  }, []);
}
